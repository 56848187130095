.connectHue {
  height: 100px;
  width: 100px;
  margin: auto;
  padding: 39px 0px;
  font-size: 12px;
  text-align: center;
  border-radius: 50%;
  background: #ff9900;
  
  &:hover {
    box-shadow: 2px 2px 0px 0px #46433e;
  }
}

.bulbInnerContainer {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  text-align: center;
}

.light {
  width: 150px;
  display: inline-block;
  margin-bottom: 50px;
}

$yellow: #f1c40f;
$base-size-lb: 80px;

.lightBulb {
  margin: auto;
  width: $base-size-lb;
  text-align: center;

  .base {
    display: inline-block;
    
    .bulb {
      // z-index: 1;
      width: $base-size-lb;
      height: $base-size-lb;
      background-color: lighten($yellow, 25);
      box-shadow: 0 0 50px lighten($yellow, 25);
      border-radius: 100%;
      transition: all .6s;
    }
  }
}