.filterModal {
  background-color: white;
  position: fixed;
  left: 0;
  width: 100%;
  height: calc(100% - 200px);
  z-index: 99;
  box-shadow: 10px 10px 5px 10px grey;
  transition: all 0.5s;

  .button {
    position: absolute;
    left: 50%;
    top: -35px;
    margin-left: -35px;
    background-color: #ff6161;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    padding: 15px;
    box-shadow: 0px 0px 10px 0px grey;
  }
}

.filterModalTop {
  position: fixed;
  left: 0;
  height: 200px;
  width: 100%;
  transition: all 0.5s;
  background-color: rgba(255, 255, 255, .15);  
  backdrop-filter: blur(10px);
}
