select {
  border: 1px solid;
  padding: 10px 5px;
  border-radius: 6px;
  margin: 5px 10px;
}

input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 5px;
  border-radius: 6px;
  margin: 5px 10px;
  width: 100%;
}