.navigator {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  background: #ffffffd9;

  .dot {
    color: #f50057;
    position: absolute;
    box-shadow: rgba(0, 0, 0, 80%) 0px 0px 25px;
    border-radius: 50%;
    z-index: 2;
    display: flex;
    justify-content: center;

    svg {
      width: 50%;
    }
  }

  .centricDot {
    .dot {
      background: #f50057;
      box-shadow: 48px 53px 15px rgba(0, 0, 0, 0.05);
      color: white;
    }
    
  }
}

.navigatorToggler {
  border-radius: 50%;
  background: grey;
  width: 70px;
  position: fixed;
  left: 50%;
  top: -35px;
  margin-left: -35px;

  background-color: #ff6161;
  box-shadow: 0px 0px 10px 0px grey;
}
