.hover-pill:hover {
  background-color: rgb(255 255 255 / 0.80) !important;
  color: #753539;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.5);
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f50057;
  border-radius: 2px;
}


