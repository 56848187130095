.hint {
  padding: 15px 20px;
  width: 100%;
  opacity: 0.8;
  color: rgba(0, 0, 0, 0.5);

  .icon {
    width: 22px;
    float: left;
    margin-right: 10px;
  }


  &.success {
    background: rgba(1, 210, 1, 0.2);
  }

  &.warning {
    background: rgba(210, 110, 1, 0.2);
  }

  &.info {
    background: rgba(0, 208, 255, 0.2);
  }

  &.error {
    background: rgba(255, 0, 0, 0.2);
  }
}