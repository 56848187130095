@media (max-width: 760px) {
  /* For mobile phones: */
  [class*="columns-"] {
    width: 100% !important;
  }
}

.grid {
  &.wrapper {
    max-width: 1900px;
    margin: 50px auto;
  }

  &.clear {
    clear: both;
  }

  &.container {
    padding: 50px;
  }

  &.spacing {
    &-5 {
      padding: 5px;
    }

    &-10 {
      padding: 10px;
    }
  }

  &.columns {
    &-12 {
      width: 100%;
      float:left;
    }

    &-3 {
      width: 33.333%;
      float:left;
    }

    &-2 {
      width: 50%;
      float:left;
    }
  }
}