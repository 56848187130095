.mainBackground {
  background: rgba(255, 255, 255, 0);
  background: radial-gradient(500px, #040404, #24d88b, #ffffff);

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}