$eyecolor: #24e595;
$eyecolorAlarm: #e52424;
$eyecolorDark: #00864e;
$eyecolorAlarmDark: #c90000;

@keyframes pulse {
	0% {
		transform: scale(0.95) rotate(10deg);
	}

	70% {
		transform: scale(1) rotate(0deg);
	}

	100% {
		transform: scale(0.95) rotate(10deg);
	}
}

.eyeholder {
    height: 100px;
    width: 100px;
    margin: auto;

    * {
      padding:0;
      margin:0;
    }

    transform: scale(1);
	  animation: pulse 2s infinite;

    .eyeball {
      height:100px;
      width:100px;
      border-radius:100%;
      position:absolute;
      left:50%;
      margin: 0px 0 0 -50px;
      background: $eyecolor;
      background: -moz-radial-gradient(center, ellipse cover,  $eyecolorDark 2%, $eyecolor 73%, #0d1b44 100%);
      background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(2%,$eyecolor), color-stop(73%,$eyecolor), color-stop(100%,#0d1b44));
      background: -webkit-radial-gradient(center, ellipse cover,  $eyecolorDark 2%,$eyecolor 73%,#0d1b44 100%);
      background: -o-radial-gradient(center, ellipse cover,  $eyecolorDark 2%,$eyecolor 73%,#0d1b44 100%);
      background: -ms-radial-gradient(center, ellipse cover,  $eyecolorDark 2%,$eyecolor 73%,#0d1b44 100%);
      background: radial-gradient(ellipse at center,  $eyecolor 2%,$eyecolor 73%,#0d1b44 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$eyecolor', endColorstr='#0d1b44',GradientType=1 );

        &.alarm {
          background: $eyecolorAlarm;
          background: -moz-radial-gradient(center, ellipse cover,  $eyecolorAlarm 2%, $eyecolorAlarm 73%, #0d1b44 100%);
          background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(2%,$eyecolorAlarm), color-stop(73%,$eyecolorAlarm), color-stop(100%,#0d1b44));
          background: -webkit-radial-gradient(center, ellipse cover,  $eyecolorAlarmDark 2%,$eyecolorAlarm 73%,#0d1b44 100%);
          background: -o-radial-gradient(center, ellipse cover,  $eyecolorAlarmDark 2%,$eyecolorAlarm 73%,#0d1b44 100%);
          background: -ms-radial-gradient(center, ellipse cover,  $eyecolorAlarmDark 2%,$eyecolorAlarm 73%,#0d1b44 100%);
          background: radial-gradient(ellipse at center,  $eyecolorAlarmDark 2%,$eyecolorAlarm 73%,#0d1b44 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$eyecolor', endColorstr='#0d1b44',GradientType=1 );
        }

        .iris {
          background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 99%);
          background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,1)), color-stop(50%,rgba(0,0,0,0)), color-stop(99%,rgba(0,0,0,0)));
          background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 99%);
          background: -o-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 99%);
          background: -ms-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 99%);
          background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 99%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 );
          height: 100px;
          width: 100px;
          border-radius: 100%;
          -webkit-transform: rotate(-40deg);
          -moz-transform: rotate(-40deg);
          -ms-transform: rotate(-40deg);
          -o-transform: rotate(-40deg);
          transform: rotate(-40deg);

          .iris1 {
            background: -moz-linear-gradient(top,  rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 99%);
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.7)), color-stop(50%,rgba(0,0,0,0)), color-stop(99%,rgba(0,0,0,0)));
            background: -webkit-linear-gradient(top,  rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 99%);
            background: -o-linear-gradient(top,  rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 99%);
            background: -ms-linear-gradient(top,  rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 99%);
            background: linear-gradient(to bottom,  rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 99%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3000000', endColorstr='#00000000',GradientType=0 );
            height: 100px;
            width: 100px;
            border-radius: 100%;
            -webkit-transform: rotate(60deg);
            -moz-transform: rotate(-60deg);
            -ms-transform: rotate(-60deg);
            -o-transform: rotate(-60deg);
            transform: rotate(-60deg);
            box-shadow: 0 0 25px 1px inset;

            .blackball {
              background:#000;
              height:26px;
              width:26px;
              position:absolute;
              top:50%;
              left: 50%;
              margin: -13px 0 0 -13px;
              border-radius:100%;
              z-index:1;
              -webkit-filter: blur(1px);
              -moz-filter: blur(1px);
              -ms-filter: blur(1px);
              -o-filter: blur(1px);
              filter: blur(1px);

              .ballshine {
                background:#fff;
                height:5px;
                width:5px;
                position:absolute;
                top:20%;
                right:0%;
                box-shadow: 0 0 9px 6px #fff;
                border-radius:100%;
              }
            }

          .effect1 {
            background: $eyecolor;
            height: 1px;
            width: 1px;
            box-shadow: 0 0 15px 17px $eyecolor;
            position: absolute;
            top: 46%;
            right: 29%;
            z-index: 0;

            &.alarm {
                background: $eyecolorAlarm;
                box-shadow: 0 0 15px 17px $eyecolorAlarmDark;
            }
          }
        }
    }
  }
}
