.filterPill {
  background: #99ecff;
  padding: 0 20px 0 0;
  margin: 2px;
  float: left;
  border-radius: 20px;

  &:hover {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.30);
  }

  .deleteFilter {
    float: left;
    width: 37px;
    height: 37px;
    margin: 0px 0px;
    padding: 9px;

    &:hover {
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.30);
      border-radius: 50%;
    }
  }

  .filterKey {
    margin: 10px 10px 10px 5px;
    padding: 0;
    float: left;
  }

  .filterValue {
    padding: 10px 15px;
    float: left;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.30) inset;
    background: white;
  }
}
