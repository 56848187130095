.paginationContainer {
  .pagination {
    float: right;

    .selectContainer {
      float: left;
    }

    .limitContainer {
      float: left;
    }

    .button {
      float: left;
      border: 1px solid #99ecff;
      border-radius: 10px;
      margin: 5px;
      padding: 10px;
      color: black;
      background: #ffffff;

      &:hover {
        background: #99ecff;
      }
    }
  }

  .paginationInformation {
    width: 100%;
  }
}