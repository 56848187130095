.dataTable {
  width: 100%;

  thead {
    td {
      border-bottom: 1px solid rgb(156, 156, 156);
      white-space: nowrap;
      padding: 15px 0 10px;
    }
  }

  tbody {
    tr {
      td {
        padding: 5px 0; 
        border-bottom: 1px dashed rgb(187, 187, 187);
      }
    }
  }

  .dataTableDiv {
    border-radius: 6px;
    padding: 7px;

    font-family: monospace;
    text-align: center;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-between;
    align-items: center;

    word-break: break-word;

    &.integerDiv {
      background: rgba(1, 210, 1, 0.2);
    }
  
    &.infoDiv {
      background: rgba(1, 210, 1, 0.2);
    }

    &.sideInfoDiv {
      background: rgba(206, 206, 206, 0.2);
    }
  
    &.identifierDiv {
      background: rgba(210, 1, 1, 0.2);
    }
  
    &.ipDiv {
      background: rgba(210, 1, 1, 0.2);
    }
  
    &.vendorDiv {
      background: rgba(101, 1, 210, 0.2);
    }
  
    &.deviceDiv {
      background: rgba(1, 27, 210, 0.2);
    }

    &.dateDiv {
      background: rgba(0, 208, 255, 0.4);
      margin-bottom: 2px;
      padding: 4px;
      border-radius: 6px;
    }

    &.timeDiv {
      background:  rgba(0, 208, 255, 0.2);
      padding: 4px;
      border-radius: 6px;
    }
  }
}

@media (max-width: 760px) {
  table, thead, tbody, th, td, tr { 
		display: block; 
	}

  thead {
    tr { 
      position: absolute;
      top: -9999px;
      left: -9999px;
    } 
  }

  tr {
    margin-bottom: 40px;
  }
	
  tbody {
    td { 
      border: none;
      position: relative;
      padding-left: 50%;
  
      > .clear {
        float: none;
        clear: both;
      }
  
      &:before { 
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%; 
        padding-right: 10px; 
        white-space: nowrap;
        font-family: Helvetica Neue;
        font-weight: bolder;
        content: attr(data-label) ":";
      }
    }
  }	
}