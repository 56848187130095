.typography {
  font-family: Helvetica Neue;
  font-weight: 400;

  h1 {
    // color: pink;
    font-weight: 100;
  }

  h2 {
    margin: 0px 0 10px;
    font-weight: 100;
  }

  h3 {
    margin: 0px 0 10px;
    font-weight: 100;
  }

  label {
    margin: 0px 0 10px;
    font-weight: 100;
  }

  .technical {
    padding: 20px;
    white-space: pre-wrap;
    font-family: monospace;
  }

  pre {
    padding: 20px;
    white-space: pre-wrap;
    background: #ffffff4a;
    border-radius: 5px;
    box-shadow: 0px 0px 8px inset #88888847;
    margin: 25px 0;
  }

  .caption {
    font-weight: 100;
  }

  &.textAlign {
    &-left {
      text-align: left;
    }

    &-center {
      text-align: center;
    }
  }

  &.caption {
    color: grey;
    text-align: left;
    padding: 5px 0;
    line-height: 1.4;
    max-width: 500px;
  }
}
