.card {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 0;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.2);

  &:hover {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }

  .cardIcon {
    margin: auto;
    opacity: 0.5;
    width: 100px;
  }

  > .domain {
    padding: 30px 20px;
  }

  > .description {
    padding: 10px 20px;
    max-width: 250px;
    height: 154px;
    margin: auto;
  }

  .cardHeader {
    padding: 15px 0;
  }

  .cardDescription {
    padding: 5px 0;
  }

  .cardChildren {
    padding: 20px 0;
    background: rgba(255, 255, 255, 0.5);
  }
}